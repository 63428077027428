import React from "react";

import { useGlobalContext } from "./context/globalContext";

const InfoBox = () => {
  const { handleShowModal } = useGlobalContext();

  return (
    <div>
      <div className="info-box overflow-hidden">
        <span className="pro-status">Coming Soon</span>
        <h1 className="pro-title">Adani Realty Thane New Launch</h1>
        <div>
          <h3 className="pro-dev">At Teen Hath Naka, Thane West</h3>
        </div>
        <span className="d-block mb-1 text-capitalize of-box offer-bg-animation">
          <span className="offer-text-outer">
            <span className="offer-text">
              Low Density Development Preference in Early Choice Of Inventory
              Exclusive for EOI Clients ONLY Book with EOI Amount ₹2 Lacs*
            </span>
          </span>
        </span>
        <span class="pro-tag-line">
          Spacious <b> 2, 3 &amp; 4 BHK</b> Starts At
        </span>{" "}
        <span class="pro-price">
          <span class="strike">₹ 2.16 Cr</span>
          <span>₹ 1.82 Cr</span>
          <small style={{ fontSize: "16px" }}> Onwards.</small>
        </span>
        <span className="d-block mb-1 text-capitalize of-box offer-bg-animation">
          <span className="offer-text-outer" style={{ color: "black" }}>
            <span className="offer-text" style={{ padding: "0px" }}>
              Site Visits By Invitation
            </span>
          </span>
        </span>
        <span className="d-block"></span>
        <span class="cd-head text-uppercase">Act now — Offer Ends In ⏳</span>
        <button
          className="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale"
          onClick={handleShowModal}
        >
          Express Your Interest
        </button>
      </div>
    </div>
  );
};

export default InfoBox;
